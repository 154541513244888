import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import instantsearch from 'instantsearch.js';
import { searchBox, stats } from 'instantsearch.js/es/widgets';

const { url = null, apiKey = null, indexName = 'begabungslotse' } = window.MeiliSearchConfig;

const searchClient = instantMeiliSearch(url, apiKey, {
    paginationTotalHits: 100,
    placeholderSearch: false,
    primaryKey: 'id',
});

const search = instantsearch({
    indexName,
    searchClient,
    routing: false,
    future: {
        preserveSharedStateOnUnmount: false,
    },
});

const getSearchUrl = (query = null) => `/suche${query ? `?${indexName}[query]=${query}` : ''}`;

search.addWidgets([
    searchBox({
        container: '[data-widget="searchbox"]',
        placeholder: 'Suchbegriff eingeben …',
        showSubmit: true,
        showLoadingIndicator: false,
        showReset: false,
        templates: {
            submit({ cssClasses }, { html }) {
                return html`<svg
                    class="${cssClasses.submitIcon}"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 512 512"
                >
                    <path
                        d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"
                    />
                </svg>`;
            },
        },
    }),
    stats({
        container: '[data-widget="stats"]',
        templates: {
            text(data, { html }) {
                return html`<a
                    class="navigation-quick-search__stats-link"
                    href="${getSearchUrl(data.query)}"
                >
                    <span class="navigation-quick-search__stats-badge">
                        ${Number(data.nbHits).toLocaleString()}
                    </span>
                    <span> Einträge</span>
                </a>`;
            },
        },
    }),
]);

search.start();

// once the form is submitted, redirect to the full search page
const searchForm = document.querySelector('.navigation-quick-search .ais-SearchBox-form');
searchForm.addEventListener('submit', e => {
    const query = searchForm.querySelector('.ais-SearchBox-input').value;
    window.location = getSearchUrl(query);
});
